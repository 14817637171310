<template>
  <div class="my-second juan">
      <Head :title="$t('mine.el_card')" :show="true" />
      <div class="common-box juan-box" v-if="list.length>0">
          <MyList ref="mylist" :listadd="listadd">
            <div  class="juan-box-item "
            v-for="(l,i) in list " :key="i">
                <img class="tick-bg" v-lazy="l.tickinfo.bg_img" alt="" >
                <div class="tick-main">
                    <div class="juan-box-item-left">
                      <img v-lazy="l.tickinfo.image" alt="">
                      <!-- <img :src="i%2==0?require('@/assets/images/tou-coffee.png'):require('@/assets/images/tou-mai.png')" alt=""> -->
                      <div class="juan-info">
                        <p class="juan-value">{{$t('home.face_value')}}:${{l.tickinfo.face_value | number_format}}</p>
                        <p class="juan-name">{{l.tickinfo.name}}</p>
                        <p class="juan-time">{{$t('info.data_before')}}:{{l.tickinfo.expired_at}}</p>
                      </div>
                  </div>
                  <div class="juan-box-item-right">
                    <van-button class="btn-use" @click="gotousejuan(l)">{{$t('mine.to_use')}}</van-button>
                  </div>
                </div>
            </div>
          </MyList>  
      </div>
      <div class="common-box juan-box" v-else>
        <div class="no-box">
            <img class="juan-no" src="../../assets/images/juan-no.png" alt="">
            <p class="no-text">{{$t('mine.no_price_card')}}</p>
        </div>
      </div>

        <van-overlay class="g-overlay" :show="show"  @click="show=false">
          <div class="touse-mask" @click.stop>
             <van-field
              :label="$t('wallet.card_num')"
              readonly
              clickable
              v-model="item.tickcode"
              >
              <template #button>
                <van-button class="copy-btn" @click="onCopy('num')">{{$t('mine.copy')}}</van-button>  
              </template>
              </van-field>
                <van-field
                  v-if="item.tickinfo"
                  :label="$t('add.card_pass')"
                  readonly
                  clickable
                  v-model="item.tickinfo.name"
              >
                  <template #button>
                      <van-button class="copy-btn" @click="onCopy('pass')">{{$t('mine.copy')}}</van-button>  
                  </template>
              </van-field>
          </div>
        </van-overlay>

  </div>
</template>

<script>
import {Toast} from "vant"
export default {
  name:"juan",
  data(){
    return {
      list:[],
      page:1,
      pageSize:20,
      total:0,
      allList:[],
      show:false,
      item:{}
    }
  },
  methods:{
    onCopy(text) {
        var flag = this.copyText(text=="pass"?this.item.tickinfo.name:this.item.tickcode);
        Toast.success(this.$t('news.copysuccess'))
    },
    copyText(shareLink) {
          var _input = document.createElement("input");   // 直接构建input
          _input.value = shareLink;  // 设置内容
          document.body.appendChild(_input);    // 添加临时实例
          _input.select();   // 选择实例内容
          document.execCommand("Copy");   // 执行复制
          document.body.removeChild(_input); // 删除临时实例
      },
    gotousejuan(item){
      this.show = true;
      this.item = item
    },
    listadd(cb1,cb2){
      if(this.page<Math.ceil(this.total/this.pageSize)){
        this.page++;
        this.$ajax.buyticketList({
          page:this.page,
          pageSize:this.pageSize
        })
        .then(res=>{
          if(res.code==200){
            this.list = this.list.concat(res.data.data);
            this.total = res.data.total;
          }
          cb1();
        })
      }else{
        cb2()
      }
    }
  },
  mounted(){
    this.$ajax.buyticketList({
      page:this.page,
      pageSize:this.pageSize
    })
    .then(res=>{
      if(res.code==200){
        this.list = res.data.data;
        this.total = res.data.total;
      }
    })
  }
}
</script>